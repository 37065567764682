// checks form HTML5 validity
export const checkValidity = formId => {
  // jquery
  const $ = window.$;

  // check validity
  return $(`#${formId}`) && $(`#${formId}`).length === 1
    ? $(`#${formId}`)[0].checkValidity()
    : false;
};

// we only send emails once per minute to prevent a runaway process
let lastEmailSent = null;

// sends an email to support
export const emailSupport = (summary, details) => {
  // if we're local, we don't need to do this;
  // we already know about the error
  if (process.env.REACT_APP_ENV === 'Local') {
    return;
  }

  // have we sent one within the past minute?
  if (lastEmailSent && Date.now() - lastEmailSent < 60) {
    console.debug('Just sent an email; ignoring this one', summary, details);
    return;
  } else {
    lastEmailSent = Date.now();
  }

  // don't let failures here affect anything else
  try {
    // build the payload
    const payload = {
      env: process.env.REACT_APP_ENV === 'Prod' ? 'production' : 'sandbox',
      action: 'support',
      summary: summary,
      details: details
        ? typeof details === 'object'
          ? details instanceof Error
            ? details.toString()
            : JSON.stringify(details, null, 2)
          : details
        : 'No details provided'
    };

    // make the call
    sendEmail(payload).catch(e => {
      // log it and eat it
      console.error('Error mailing support', e);
    });
  } catch (e) {
    console.error('Error mailing support', e);
  }
};

// sends an email
export const sendEmail = payload => {
  // get a reCAPTCHA token
  return new Promise((resolve, reject) => {
    window.grecaptcha
      .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
        action: 'support'
      })
      .then(token => {
        resolve(token);
      }, reject);
  }).then(token => {
    // got a token
    console.debug('Got reCAPTCHA token', token);

    // make the call
    return postToAWS('fgsggt0n0l', {
      ...payload,
      token: token
    });
  });
};

// gets configuration from S3; clients are resonsible for handling errors
export function getConfiguration(bundle) {
  return fetch(`/config/${bundle}.json`).then(response => response.json());
}

// creates a hash code for a string; see https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
export function hashCode(s) {
  for (var i = 0, h = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return h;
}

// formats a phone number by adding dashes where appropriate
export const formatPhone = text => {
  // if we don't have text, return
  if (typeof text === 'undefined' || text === null || text.length === 0) {
    return '';
  }

  // make sure we can manipulate it
  text = text.toString();

  // strip US prefix
  text = text.replace('+1', '');

  // strip non-digits
  text = text.replace(/\D/g, '');

  // if we don't have text any longer, return
  if (text.length === 0) {
    return '';
  }

  // split into parts
  const splitter = /.{1,3}/g;

  // put the parts together with separators
  return (
    text
      .substring(0, 7)
      .match(splitter)
      .join('-') + text.substring(7)
  );
};

// formats a zip code by adding dashes where appropriate
export const formatZip = text => {
  // if we don't have text, return
  if (typeof text === 'undefined' || text === null || text.length === 0) {
    return '';
  }

  // make sure we can manipulate it
  text = text.toString();

  // special case; user typed a dash
  const endingDash = text.endsWith('-');

  // strip non-digits
  text = text.replace(/\D/g, '');

  // if we don't have text any longer, return
  if (text.length === 0) {
    return '';
  }

  // split into parts
  const splitter = /.{1,5}/g;

  // put the parts together with separators
  text =
    text
      .substring(0, 9)
      .match(splitter)
      .join('-') + text.substring(9);

  // handle ending dash
  if (endingDash && text.length === 5) {
    text += '-';
  }

  return text;
};

// scrolls to an anchor
export function scrollToAnchor(anchor, modalId = null) {
  // jquery
  const $ = window.$;

  // safety check
  if ($(`#${anchor}`)) {
    $(document).ready(function() {
      // in modal?
      if (modalId && $(`#${modalId}`)) {
        $(`#${modalId}`).animate(
          {
            scrollTop: $(`#${anchor}`).position()
              ? $(`#${anchor}`).position().top
              : 0
          },
          200
        );
      } else {
        // we need to account for the header
        let offset = 0;
        let header = $('#header');
        if (header) {
          offset = header.height();
        }

        if ($(`#${anchor}`).offset()) {
          $('html, body').animate(
            {
              // account for the header
              scrollTop: $(`#${anchor}`).offset().top - offset
            },
            200
          );
        }
      }
    });
  }
}

// see if two VALID addresses match; addresses missing components are ignored
export function addressesMatch(a, b, compareStreet2 = false) {
  // easy case
  if ((!a && !b) || (a && b && a === b)) {
    return true;
  }

  // another easy case
  if ((a && !b) || (!a && b)) {
    return false;
  }

  // street
  if (a.street1 && b.street1) {
    // force lowercase, strip periods (but purposely nothing else),
    // and collapse multiple spaces
    const x = a.street1
      .toLowerCase()
      .replace('.', '')
      .replace(/\s\s+/g, ' ');
    const y = b.street1
      .toLowerCase()
      .replace('.', '')
      .replace(/\s\s+/g, ' ');

    if (x !== y) {
      return false;
    }
  }

  // street 2
  if (
    compareStreet2 &&
    ((a.street2 &&
      b.street2 &&
      a.street2.toLowerCase() !== b.street2.toLowerCase()) ||
      (a.street2 && !b.street2) ||
      (!a.street2 && b.street2))
  ) {
    return false;
  }

  // city
  if (a.city && b.city && a.city.toLowerCase() !== b.city.toLowerCase()) {
    return false;
  }

  // state
  if (a.state && b.state && a.state !== b.state) {
    return false;
  }

  // zip
  if (a.zip && b.zip) {
    if (a.zip.length === b.zip.length && a.zip !== b.zip) {
      return false;
    } else if (
      a.zip.length === 5 &&
      b.zip.length === 10 &&
      !b.zip.startsWith(a.zip + '-')
    ) {
      return false;
    } else if (
      b.zip.length === 5 &&
      a.zip.length === 10 &&
      !a.zip.startsWith(b.zip + '-')
    ) {
      return false;
    }
  }

  return true;
}

// promise-based XHR requests
export function call(url, method = 'GET', headers = {}, payload = null) {
  // create the XHR request
  var xhr = new XMLHttpRequest();

  // wrap it in a promise
  return new Promise(function(resolve, reject) {
    // process completed requests
    xhr.onreadystatechange = function() {
      // only run if the request is complete
      if (xhr.readyState !== 4) return;

      // process the response
      if (xhr.status >= 200 && xhr.status < 300) {
        // success
        resolve(xhr.responseText ? JSON.parse(xhr.responseText) : {});
      } else {
        // error
        reject({
          status: xhr.status,
          responseText: xhr.responseText
        });
      }
    };

    // prepare the request
    xhr.open(method, url);

    // set headers
    for (let k in headers) {
      xhr.setRequestHeader(k, headers[k]);
    }

    // content
    if (payload && typeof payload === 'object') {
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.setRequestHeader('content-type', 'application/json');
    }

    // send the request
    xhr.send(payload ? JSON.stringify(payload) : null);
  });
}

// posts to AWS
export function postToAWS(env, payload) {
  return call(
    `https://${env}.execute-api.us-east-1.amazonaws.com/prod`,
    'POST',
    {
      'x-api-key': process.env.REACT_APP_AWS_API_KEY
    },
    payload
  );
}
