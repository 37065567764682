// polyfills
import 'core-js';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'webstorage-polyfill-wrapper';

// React
import React from 'react';
import ReactDOM from 'react-dom';

// our app
import App from './App';

// wrap a provider around the application
ReactDOM.render(<App />, document.getElementById('root'));
