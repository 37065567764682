import React from 'react';

import './styles.scss';

export default function Main() {
  return (
    <>
      <main>
        <div
          class="d-flex justify-content-center align-items-center"
          style={{ height: '90vh', overflow: 'hide' }}
        >
          <div className="h1">Soon.</div>
        </div>
      </main>
    </>
  );
}
