import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { emailSupport } from './util';

/**
 * A boundary for handling errors that no one else handled.
 */
class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    // parent
    super(props);

    // keep track of errors
    this.state = {
      ...this.state,
      error: null
    };
  }

  componentDidCatch(error, info) {
    // log the error
    console.error('Unhandled error caught', error);

    // email support
    emailSupport('Unhandled error caught', error);

    // flag that we have an error
    this.setState({ error: error });
  }

  render() {
    // render
    if (this.state.error) {
      return (
        <div
          style={{
            display: 'table',
            width: '100%',
            height: '90vh',
            padding: '50px'
          }}
        >
          <section
            style={{
              display: 'table-row'
            }}
          >
            <div
              className="align-middle text-center"
              style={{
                display: 'table-cell'
              }}
            >
              <h1 style={{ color: 'red' }}>
                <FontAwesomeIcon icon="exclamation-circle" />
              </h1>
              <h4>Boom.</h4>
            </div>
          </section>
        </div>
      );
    }

    // render children
    return this.props.children;
  }
}

// set prop types and required-ness
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired
};

export default ErrorBoundary;
