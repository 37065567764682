// must come first
import outdatedBrowserRework from 'outdated-browser-rework';
import 'outdated-browser-rework/dist/style.css';

// third-party imports
import React, { useEffect } from 'react';
import {
  faSpinner,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// our imports
import ErrorBoundary from './ErrorBoundary';
import Main from './Main';

// load the Font Awesome icons that we use
library.add(faSpinner, faExclamationCircle);

export default function App(props) {
  useEffect(() => {
    // fire up the outdated browser check
    outdatedBrowserRework({
      browserSupport: {
        Chrome: 48,
        Edge: 44,
        Safari: 9.0,
        'Mobile Safari': 9.1,
        Firefox: 44,
        Opera: 35,
        Vivaldi: 1,
        IE: 11
      },
      requireChromeOnAndroid: false,
      isUnknownBrowserOK: true,
      messages: {
        en: {
          outOfDate: `<span style="text-transform: none;">That's a really old browser you've got there!</span>`,
          unsupported: `<span style="text-transform: none;">That's an unsupported browser you've got there!</span>`,
          update: {
            web:
              '<span style="text-transform: none; font-size: 1.2rem; line-height: 1.2;">' +
              "There is a good chance that the site won't look or function correctly in your browser. " +
              "You can try, but you've been warned. Your best bet is to use a modern version of a " +
              'supported browser: Chrome, Safari, Firefox or Opera. That would ' +
              'make us happy, and we think it will make you happy too.' +
              '</span>',
            googlePlay:
              '<span style="text-transform: none; line-height: 1.2;">To make sure the site works properly, please install ' +
              'a modern browser from Google Play</span>',
            appStore:
              '<span style="text-transform: none; line-height: 1.2;">To make sure the site works properly, please ' +
              'update iOS from the Settings App</span>'
          },
          url: 'http://outdatedbrowser.com/',
          callToAction: 'Update my browser now',
          close: 'Close'
        }
      }
    });
  });

  // main content
  return (
    <>
      <div id="outdated" />
      <ErrorBoundary>
        <Main />
      </ErrorBoundary>
    </>
  );
}
